import { Container, Col, Row } from "react-bootstrap";
import iapp from '../assets/iappstore.svg';

const Home = () => {
    return (
        <Container>
            <Col>
                <Row>
                    <p><b>Read between the lines with the PostOddzTM app!</b></p>
                </Row>
                <Row>
                    <Col md={{span: 4, offset: 4}}>
                        <a href='https://play.google.com/store/apps/details?id=com.dabenedict.OddzApp&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'><img style={{width: 160, height: 'auto'}} alt='Get it on Google Play' src='https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png'/></a>
                        <a href='https://apps.apple.com/us/app/postoddz/id1556973539'><img style={{width: 140, height: 'auto', marginLeft: 10}} alt='Download on the App Store' src={iapp}/></a>
                    </Col>
                </Row>
            </Col>
        </Container>
    );
}
export default Home;