import { Container } from "react-bootstrap";

const About = () => {
    return (
        <Container>
            <p>PostOddz is the premier social media network for sports betting.  The PostOddz mobile app provides gamblers and sports fans alike with a platform for posting their bets, interacting with picks posted by friends, tracking the status of their picks, and comparing their sports betting performance against other users.  PostOddz promotes transparency by being the only sports betting social media network where all posts are tied directly to picks selected by users from consensus odds. Read between the lines with PostOddz</p>
            <ul>
                <li>Posts displayed in the Feed are uniquely organized by each specific game so you can easily find Picks for the games you want to bet on.</li>
                <li>Games that are about to start are featured at the top of the Feed so you never miss a last-minute Pick by a user.</li>
                <li>Post Picks from all the most popular sports leagues using consensus lines provided by the app.</li>
                <li>Compare your performance against other users on the app using the Leaderboard and see how you stack up with rankings based on what matters most: win percentage, overall record, and total units won.</li>
                <li>Filter the Leaderboard to see who the best bettors are depending on the league, pick type, and time frame.</li>
                <li>Use the Search function to find your friends and other users to follow to see their Picks.</li>
                <li>Track your Picks and monitor your sports betting performance on your Profile.</li>
                <li>Compete in contests hosted in the app with the chance to win real prizes!</li>
            </ul>
            <p>PostOddz is not a sportsbook or gambling site, and no real money can be wagered through the PostOddz mobile app.  Content, information, odds, and other lines posted on the PostOddz mobile app are for entertainment purposes only.  PostOddz does not endorse illegal gambling, and all users should refer to local gambling laws.  If you or someone you know has a gambling problem and wants help, call 1-800-GAMBLER. PostOddz is not affiliated with or endorsed by Apple Inc., the NLF, MLB, NBA, NHL, PGA, the NCAA or any other sports league.</p>
        </Container>
    );
}
export default About;