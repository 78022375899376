import { Container, Image, Row, Col } from "react-bootstrap";
import title from '../assets/PostOddz_Watermark_Purple_c.png';

const Header = () => {
    return (
        <Container>
            <Container style={{margin: 10}} fluid>
                <Row>
                    <Col md={{span: 10, offset: 1}}>
                        <Image src={title} fluid />
                    </Col>
                </Row>
            </Container>
        </Container>
    );
}

export default Header;