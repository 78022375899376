import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter as Router } from "react-router-dom";
import { Container, Row, Col } from 'react-bootstrap';
import Header from './common/Header';
import CustomNavbar from './common/CustomNavbar';

function App() {
  return (
    <Router>
      <Container>
        <Row>
          <Col md={{span: 8, offset: 2}}>
            <Header/>
            <CustomNavbar/>
          </Col>
        </Row>
      </Container>
    </Router>
  );
}

export default App;
