import { Nav, Navbar, Container } from "react-bootstrap";
import { Switch, Route, Link } from "react-router-dom";
import logo from '../assets/PostOddz_widget.png';
import About from '../about/index.js';
import Contact from '../contact/index.js';
import PrivacyPolicy from '../privacy-policy/index.js';
import ContestRules from "../contest-rules/index.js";
import Home from '../home/index.js';

// "#7879F1"
const CustomNavbar = () => {
    return (
        <div>
            <Navbar bg="light" expand="lg">
                <Container>
                    <Navbar.Brand as={Link} to="/">
                        <img 
                            src={logo}
                            width="45"
                            height="45"
                            className="d-inline-block align-top"
                            alt="PostOddz logo"
                        />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/about">About</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/contact">Contact</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/contest-rules">Contest Rules</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link as={Link} to="/privacy-policy">Privacy Policy</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <div style={{paddingTop: 20}}>
                <Switch>
                    <Route exact path='/'>
                        <Home/>
                    </Route>
                    <Route path="/about">
                        <About/>
                    </Route>
                    <Route path="/contact">
                        <Contact/>
                    </Route>
                    <Route path="/contest-rules">
                        <ContestRules/>
                    </Route>
                    <Route path="/privacy-policy">
                        <PrivacyPolicy/>
                    </Route>
                </Switch>
            </div>
        </div>
    );
}
export default CustomNavbar;