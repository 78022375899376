import { Container } from "react-bootstrap";

const ContestRules = () => {
    return (
        <Container>
            <p><b>PostOddz® is back with the NBA Playoffs League Contest!</b> Throughout the course of the NBA Playoffs, users of the PostOddz® App will have the chance to utilize the new Leagues feature and compete for $50 by submitting Picks on NBA Playoff games.</p>
            <ol>
                <li><b>Entry Requirements</b></li>
                <ul>
                    <li>Create a PostOddz® Account on the App using a valid email address</li>
                    <li>Join the PostOddz® Official NBA Playoffs League through the League function on the App</li>
                </ul>
                <br/>
                <li><b>Contest Information</b></li>
                <ul>
                    <li>Contest Period begins on April 16th, 2022, with the beginning of the NBA Playoffs and ends on June 20th, 2022, following the conclusion of the NBA Playoffs</li>
                    <li>All single moneyline (“ML”), spread (“ATS”), and total points (“O/U”) Picks made through the League are eligible</li>
                </ul>
                <br/>
                <li><b>How to Win</b></li>
                <ul>
                    <li>Wager between 0.5 and 5.0 Units (in increments of 0.5 Units) on NBA Playoff Picks during the playoffs through the League</li>
                    <li>Have the highest Total Units won from Picks at the end of the Contest Period</li>
                </ul>
                <br/>
                <li><b>The Prize</b></li>
                <ul>
                    <li>One (1) $50 gift card will be awarded to the user with the most Total Units won at the end of the Contest Period</li>
                </ul>
                <br/>
            </ol>
            <p>For questions and concerns regarding the Contest, please email us at <a href = "mailto: info@postoddz.app">info@postoddz.app</a>.</p>
            <p>NO PURCHASE NECESSARY TO ENTER OR WIN.  NO REAL MONEY CAN BE WAGERED THROUGH THE POSTODDZ® MOBILE APP.</p>
            <br/>
        </Container>
    );
}
export default ContestRules;